@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tamma+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap");

@layer base {
  @font-face {
    font-family: Lunema;
    font-weight: 900;
    src: url("../src/assets/Fonts/Lunema Ultrabold.otf");
  }
}

.text-balance {
  text-wrap: balance;
}

.modal_wrapper__yA88Q,
.modal_wrapper1__N6a8s {
  z-index: 9999 !important;
}

.custom-contexmenu {
  position: absolute;
  width: 200px;
  border-radius: 5px;
  box-sizing: border-box;
}

.scroll, body, .custom-main-content {
  scrollbar-color: var(--scroll-thumb-color, grey)
    var(--scroll-track, transparent);
  scrollbar-width: thin;
}
.scroll::-webkit-scrollbar, body::-webkit-scrollbar, .custom-main-content::-webkit-scrollbar {
  width: var(--scroll-size, 10px);
  height: var(--scroll-size, 10px);
}
.scroll::-webkit-scrollbar-track, body::-webkit-scrollbar-track, .custom-main-content::-webkit-scrollbar-track{
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}
.scroll::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb , .custom-main-content::-webkit-scrollbar-thumb{
  background-color: var(--scroll-thumb-color, grey);
  background-image: var(--scroll-thumb, none);
  border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
}

.scroll-3, body, .custom-main-content {
  --scroll-size: 5px;
  --scroll-radius: 10px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb-color: #fff;
}

.contextContainer {
  z-index: 9999;
  width: 100%;
}
.rightClick {
  z-index: 12;
  position: fixed;
}

.loader-screen {
  position: absolute;
  z-index: 9;
  background: #000;
  opacity: 0.5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

ul {
  list-style-type: none !important;
}