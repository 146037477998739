.container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;
    padding-bottom: 20px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding-right: 50px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 0.5fr);
    grid-gap: 10px;
    padding-right: 50px;
}


.footerLogo{
    margin-left:100px;
}

.footerImage{
    height: 56px;
    weight: 167px;
    margin-bottom: 20px;
}

ul{
    list-style-type: none;
    padding: 0;
}

li{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #123962;
    padding-bottom: 10px;
}

.copyright{
    padding: 20px;
    margin-left:100px;
    font-size: 16px;
}
.para{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #123962;
    padding-bottom: 10px;
}

.img{
    padding-right: 20px;
    height: 15px;
    weight: 15px;
}

@media screen and (max-width: 1025px){
    .container{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .gridContainer{
        padding-right: 20px;
    }

    .lastSection{
        padding: 50px 100px;
    }
}

@media screen and (max-width: 420px){
    .container{
        display: none;
    }

    .lastSection{
        padding: 20px;
    }

    .copyright{
        grid-gap: 60px;
    }
}