.container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    position: sticky;
    align-items: center;
    background-color: #F6F6F6;
    box-sizing: border-box;
    opacity: 1;
    height: 64px;
    color: black;
    top: 0;
    left: 120vw;
    padding-left: 60px;
    padding-right: 100px;
    z-index: 3;
  }
  
  .signedInContainer {
    width: 34vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    opacity: 1;
    color: black;
    top: 0;
    left: 120vw;
    padding-left: 100px;
    z-index: 3;
  }
    
  .navbar{
    display: flex;
    position: fixed;
    z-index: 3;
  }
  
  .signinnavbar{
    position: absolute;
    z-index: 3;
  }
  
  .logo{
    width: 200px;
    height: 32px;
  }

  .btnsContainer{
    margin-top: 10px;
  }
  
  .name {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: black;
  }
  
  /* NAV */
  .menuIcon {
    display: none;
    cursor: pointer;
  }
  
  .closeIconCtnr {
    display: none;
    cursor: pointer;
  }
  
  .signinnav {
    display: flex;
  }
  
  .nav {
    margin-left: auto;
    display: flex;
  }
  
  .navEleContainer {
    padding-left: 100px;
    display: flex;
    padding-right: 100px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .avatarName{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    visibility: visible;
    color: black;
    margin-top: 13px;
    margin-left: 6rem;
  }

  .howitworks{
    font-family: 'Inter', sans-serif;
    color: black;
    font-weight: 600;
    text-decoration: none;
    font-size: 15px;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 5px;
    padding-left: 25px;
    border-radius: 15px;
    margin-left: 60px;
    cursor: pointer;
  }
  
  .SignInBtn{
    font-family: 'Inter', sans-serif;
    color: black;
    font-weight: 600;
    text-decoration: none;
    font-size: 15px;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 5px;
    padding-left: 25px;
    border-radius: 15px;
    margin-left: 60px;
    cursor: pointer;
  }

  .SignOutBtn{
    font-family: 'Inter', sans-serif;
    color: white;
    font-weight: 400;
    background-color: #3481E9;
    text-decoration: none;
    font-size: 15px;
    text-align: center;
    padding: 7px 23px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  .SignUpBtn{
    font-family: 'Inter', sans-serif;
    color: white;
    font-weight: 400;
    background-color: #3481E9;
    text-decoration: none;
    font-size: 15px;
    text-align: center;
    padding: 7px 23px;
    border-radius: 10px;
    margin-left: 30px;
    cursor: pointer;
  }
  
  
  .navLink {
    font-family: 'Inter', sans-serif;
    color: black;
    font-weight: 400;
    text-decoration: none;
    font-size: 15px;
    padding: 6px 20px;
    border-radius: 15px;
    margin-left: 30px;
  }


  
  @media only screen and (max-width: 1280px) {
    .container {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      padding-left:20px;
      justify-content: space-between;
      padding-right:40px;
    }

    .logo{
      width: 140px;
      height: 30px;
    }
  }
  
  @media only screen and (max-width: 1025px) {
    .container {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      padding-left:20px;
      justify-content: space-between;
      padding-right:0px;
    }
  
    .name {
      font-size: 20px;
    }
  
    .menuIcon {
      /* visibility: 1; */
      margin-right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: black;
    }
  
    .closeIconCtnr {
      display: flex;
      justify-content: flex-end;
      font-size: 32px;
      color: black;
      align-items: center;
      box-sizing: border-box;
      width: 100vw;
      margin-bottom: 16px;
      padding: 0 12px;
    }
  
    .nav {
      display: flex;
      height: 200px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: fixed;
      top: 0;
      right: -100vw;
      width: 100vw;
      height: 100vh;
      padding: 15px;
      background-color: white;
    }
  
    .navEleContainer {
      padding-left:15px;
      padding-right:0px;
      display: grid;
      place-items: center;
      flex-direction: column;
    }
  
    .navLink {
      color:black;
      margin: 0 0 10px 0;
      font-size: 18px;
    }
  
    .navLink:hover {
      color: black;
      margin: 0 0 10px 0;
      border: none;
    }
  
    .btnsContainer {
      margin-left: 83px;
      font-size: 18px;
    }
  
    .logo{
      margin-left: 10px;
      width: 250px;
      height: 48px;
    }
  }