.contextMenu {
    position: fixed;
    border: 1px solid #FCFCFC;
    background-color: white;
    border-radius: 15px; 
    box-shadow: 15px 20px 25px rgba(0,0,0,0.5);
}

.contextMenu .contextMenuItem {
    padding: 15px;
}

.contextMenu .contextMenuItem:hover {
    background-color: lightblue;
    cursor: pointer;
}